import PropTypes from "prop-types"
import React from "react"

const Checkbox = ({ className, click, defaultChecked, required }) => (
  <input
    className={className}
    type="checkbox"
    onClick={click}
    defaultChecked={defaultChecked}
    required={required}
  />
)

Checkbox.propTypes = {
  className: PropTypes.string,
  defaultChecked: PropTypes.bool,
  required: PropTypes.bool,
  onClick: PropTypes.func,
}

Checkbox.defaultProps = {
  className: null,
  defaultChecked: null,
  type: "checkbox",
  onClick: null,
  required: false,
}

export default Checkbox
