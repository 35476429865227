import React, { Component } from "react"
import { renderEmail } from "react-html-email"
import moment from "moment"
import LoadService from "../../services/load-service"
import AssetService from "../../services/assets-service"
import Loader from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Logo from "../../images/A.svg"
import EmailBody from "../shared/email"
import Button from "../shared/button"
import Checkbox from "../shared/checkbox"
// import SignatureCanvas from "react-signature-canvas"
import InputMask from "react-input-mask"
import styled from "styled-components"

// const Canvas = styled.div`
//   width: 500px;
//   height: 200px;
//   @media (min-width: 1025px) and (max-width: 1280px) {
//     width: 500px;
//   }
//   @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
//     width: 500px;
//   }
//   @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
//     width: 500px;
//   }
//   @media (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
//     width: 500px;
//   }
//   @media (min-width: 481px) and (max-width: 767px) {
//     width: 300px;
//   }
//   @media (min-width: 320px) and (max-width: 480px) {
//     width: 330px;
//   }
//   @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
//     width: 300px;
//   }
//   @media (max-width: 320px) {
//     width: 230px;
//   }
// `
const Stop = styled.div`
  height: auto;
  @media (min-width: 1024px) {
    height: 580px;
  }
`
const emailStyle = {
  container: {
    width: "100%",
    overflow: "auto",
    marginTop: "20px",
  },
  card: {
    maxWidth: "100%",
    overflow: "hidden",
    marginBottom: "1rem",
  },
  cardbody: {
    display: "flex",
  },
  space: {
    paddingLeft: "40px",
  },
  stoptype: {
    pickup: {
      margin: "0px",
      color: "#fc8181",
    },
    delivery: {
      margin: "0px",
      color: "#63b3ed",
    },
  },
  label: {
    color: "#718096",
    marginTop: "15px",
    marginBottom: "0px",
  },
  value: {
    margin: "0px",
  },
}

class RateconContent extends Component {
  initialState = {
    load: null,
    attachment: null,
    dispatcher: null,
    name: "",
    driverName: "",
    phone: "",
    truckNum: "",
    trailerNum: "",
    ip: "",
    agree: false,
    isSigned: false,
    success: false,
    error: false,
    working: false,
    extendedLoad: null,
  }
  loadService = null
  assetService = null
  sigPad = {}
  formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  })

  constructor(props) {
    super(props)
    this.loadService = new LoadService()
    this.assetService = new AssetService()
    this.state = this.initialState
    // this.clear = this.clear.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getCarrierRate = this.getCarrierRate.bind(this)
  }

  componentDidMount() {
    const getLoad = async () => {
      const ipdata = await this.loadService.GetIp()
      const ip = ipdata.split("\n")[2].split("=")[1]
      this.setState({ ip: ip })
      const loadObj = await this.loadService.GetTrip(this.props.tripNumber)
      console.log(loadObj)
      this.setState({ loadObj: this.initLoad(loadObj) })
      getDispatcher(
        loadObj.Trip.DispatchedBy
          ? loadObj.Trip.DispatchedBy
          : this.props.userId
      )
      this.fetchAssetInfo(loadObj)
      const attachment = await this.loadService.GetDocumentById(
        this.props.attachmentId
      )
      this.setState({ attachment: attachment })
      if (
        attachment.DateSigned !== null &&
        attachment.DateSigned !== "0001-01-01T00:00:00"
      ) {
        this.setState({ isSigned: true })
      }
    }
    const getDispatcher = async id => {
      const dispatcher = await this.loadService.GetUserById(id)
      this.setState({ dispatcher: dispatcher })
    }
    if (this.props.tripNumber) {
      getLoad()
    }
  }
  initLoad(extendedLoad) {
    const companies = extendedLoad.TripCompanies
    const stops = extendedLoad.Trip.Stops
    //const invoice = extendedLoad.Load.Invoice
    let load = extendedLoad
    load.Trip.Stops = stops.map(e => {
      e.Company = companies.find(x => x.id === e.CompanyId)
      return e
    })
    return load
  }
  getCarrierRate() {
    if (this.state.loadObj) {
      var rate =
        this.state.loadObj.TripCarrier.CarrierType === "Owner Operator"
          ? this.state.loadObj.Trip.OORate
          : this.state.loadObj.Trip.CarrierRate
      return this.formatter.format(rate)
    } else {
      return this.formatter.format(0)
    }
  }
  printCharge(charge) {
    if (
      this.state.loadObj.Trip.CarrierType === "Owner Operator" &&
      charge.OOAmount !== 0
    ) {
      return `
        <td className="table-cell inline-block pl-3 border-none">${
          charge.Reason
        }</td>
        <td className="table-cell inline-block pl-0 lg:pl-2 border-none">${this.formatter.format(
          charge.OOAmount
        )} ${charge.Fee ? "($" + charge.Fee + " Fee)" : ""}</td>
      `
    } else if (charge.CarrierAmount !== 0) {
      return `
        <td className="table-cell inline-block pl-3 border-none">${
          charge.Reason
        }</td>
        <td className="table-cell inline-block pl-0 lg:pl-2 border-none">${this.formatter.format(
          charge.CarrierAmount
        )} ${charge.Fee ? "($" + charge.Fee + " Fee)" : ""}</td>
      `
    } else {
      return null
    }
  }
  getTotalCharges() {
    const { loadObj } = this.state
    var rate =
      loadObj.TripCarrier.CarrierType === "Owner Operator"
        ? loadObj.Trip.OORate
        : loadObj.Trip.CarrierRate
    var invoiceTotal = 0
    if (loadObj.Invoices && loadObj.Invoices.length > 0) {
      if (loadObj.Trip.CarrierType === "Owner Operator") {
        invoiceTotal = loadObj.Invoices.map(e => e.OOAmount + e.Fee).reduce(
          (a, b) => {
            return a + b
          },
          0
        )
      } else {
        invoiceTotal = loadObj.Invoices.map(
          e => e.CarrierAmount + e.Fee
        ).reduce((a, b) => {
          return a + b
        }, 0)
      }
    }

    var total = rate + invoiceTotal
    return this.formatter.format(total)
  }

  // clear() {
  //   this.sigPad.clear()
  // }

  reset = () => {
    this.setState({ name: "" })
  }

  handleDisclaimer = () => {
    this.setState(({ agree }) => ({
      agree: !agree,
    }))
  }

  createCheckbox = () => (
    <Checkbox
      className="mr-2 leading-tight"
      click={() => this.handleDisclaimer()}
      defaultChecked={this.state.agree}
      required={true}
    />
  )
  fetchAssetInfo(loadObj) {
    if (
      loadObj.TripDrivers &&
      loadObj.TripDrivers.length > 0 &&
      loadObj.TripDrivers[0]
    ) {
      const driver = loadObj.TripDrivers[0]
      this.setState({ driverName: driver.Name })
      if (
        driver.Address &&
        driver.Address.Phone &&
        driver.Address.Phone.length > 0
      ) {
        this.setState({ phone: driver.Address.Phone[0] })
      }
    }
    if (loadObj.TripTruck) {
      this.setState({ truckNum: loadObj.TripTruck.TruckNum })
    }
    if (loadObj.TripTrailer) {
      this.setState({
        trailerNum: loadObj.TripTrailer.TrailerNum,
      })
    }
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  handleSubmit(event) {
    event.preventDefault()
    const {
      loadObj,
      name,
      phone,
      driverName,
      truckNum,
      trailerNum,
    } = this.state
    //if (!this.sigPad.isEmpty()) {
    var signature = {
      SignatureType: "name",
      Name: driverName,
      Phone: phone,
      TruckNum: truckNum,
      TrailerNum: trailerNum,
      SignatureValue: name,
      // .getTrimmedCanvas()
      // .toDataURL("image/png")
      // .split("base64,")[1],
    }
    var signLoadObj = {} //this.state.extendedLoad
    signLoadObj.LoadTrip = loadObj
    signLoadObj.Signature = signature
    this.setState({ working: true })
    this.generateRateCon(signLoadObj).then(data => {
      if (data) {
        this.setState({ success: true })
        this.setState({ working: false })
      } else {
        console.log(data)
        this.setState({ error: true })
      }
    })
    //}
  }
  assignDriver = async () => {
    if (this.state.phone && this.state.driverName) {
      if (
        this.state.loadObj.TripDrivers &&
        this.state.loadObj.TripDrivers.length > 0 &&
        this.state.loadObj.TripDrivers[0]
      ) {
        if (
          this.state.loadObj.TripDrivers[0].Name === this.state.driverName &&
          this.state.loadObj.TripDrivers[0].Address.Phone[0] ===
            this.state.driverPhone
        ) {
          return this.state.loadObj.TripDrivers[0]
        } else {
          const driver = await this.addDriver()
          const loadTrip = Object.assign({}, this.state.loadObj)
          loadTrip.Trip.DriverIds = [driver.id]
          loadTrip.TripDrivers = [driver]
          var log = {
            ParentId: loadTrip.Load.LoadNumber,
            ParentType: "Load",
            FieldName: "Driver",
            LogType: "Load Updated",
            Description: `Driver set to ${driver.Name}`,
            Time: new Date(),
            User: this.state.name,
            NewValue: driver.Name,
          }
          const tripAction = {
            LoadTrip: loadTrip,
            Logs: [log],
          }
          const res = await this.loadService.AssignDriver(tripAction)
          this.setState({ loadObj: this.initLoad(res) })
          return res
        }
      } else {
        const driver = await this.addDriver()
        const loadTrip = Object.assign({}, this.state.loadObj)
        loadTrip.Trip.DriverIds = [driver.id]
        loadTrip.TripDrivers = [driver]
        var log = {
          ParentId: loadTrip.Load.LoadNumber,
          ParentType: "Load",
          FieldName: "Driver",
          LogType: "Load Updated",
          Description: `Driver set to ${driver.Name}`,
          Time: new Date(),
          User: this.state.name,
          NewValue: driver.Name,
        }
        const tripAction = {
          LoadTrip: loadTrip,
          Logs: [log],
        }
        const res = await this.loadService.AssignDriver(tripAction)
        this.setState({ loadObj: this.initLoad(res) })
        return res
      }
    } else {
      return null
    }
  }
  addDriver = async () => {
    const res = await this.assetService.DoesDriverExist(this.state.phone)
    if (!res) {
      var driverObj = {
        ParentId: this.state.loadObj.Trip.CarrierId,
        Name: this.state.driverName,
        Address: {
          Phone: [this.state.phone],
        },
      }
      const driver = await this.assetService.CreateDriver(driverObj)
      return driver.AssetEntity
    } else {
      return res.AssetEntity
    }
  }

  assignTruck = async () => {
    if (this.state.truckNum) {
      var truckObj = {
        ParentId: this.state.loadObj.Trip.CarrierId,
        TruckNum: this.state.truckNum,
      }
      const truck = await this.assetService.CreateTruck(truckObj)
      const loadTrip = Object.assign({}, this.state.loadObj)
      loadTrip.Trip.TruckId = truck.AssetEntity.id
      loadTrip.TripTruck = truck.AssetEntity
      var log = {
        ParentId: loadTrip.Load.LoadNumber,
        ParentType: "Load",
        FieldName: "Truck",
        LogType: "Load Updated",
        Description: `Truck set to ${truck.AssetEntity.TruckNum}`,
        Time: new Date(),
        User: this.state.name,
        NewValue: truck.AssetEntity.TruckNum,
      }
      const tripAction = {
        LoadTrip: loadTrip,
        Logs: [log],
      }
      const res = await this.loadService.AssignTruck(tripAction)
      this.setState({ loadObj: this.initLoad(res) })
      return res
    } else {
      return null
    }
  }
  assignTrailer = async () => {
    if (this.state.trailerNum) {
      var trailerObj = {
        ParentId: this.state.loadObj.Trip.CarrierId,
        TrailerNum: this.state.trailerNum,
      }
      const trailer = await this.assetService.CreateTrailer(trailerObj)
      const loadTrip = Object.assign({}, this.state.loadObj)
      loadTrip.Trip.TrailerId = trailer.AssetEntity.id
      loadTrip.TripTrailer = trailer.AssetEntity
      var log = {
        ParentId: loadTrip.Load.LoadNumber,
        ParentType: "Load",
        FieldName: "Trailer",
        LogType: "Load Updated",
        Description: `Truck set to ${trailer.AssetEntity.TrailerNum}`,
        Time: new Date(),
        User: this.state.name,
        NewValue: trailer.AssetEntity.TrailerNum,
      }
      const tripAction = {
        LoadTrip: loadTrip,
        Logs: [log],
      }
      const res = await this.loadService.AssignTrailer(tripAction)
      this.setState({ loadObj: this.initLoad(res) })
      return res
    } else {
      return null
    }
  }
  generateRateCon = async obj => {
    const signedRateCon = await this.loadService.GenerateSignedRateConfirmation(
      obj
    )

    if (!signedRateCon) {
      return false
    }

    await this.assignDriver()
    await this.assignTruck()
    await this.assignTrailer()

    var loadObj = Object.assign({}, this.state.loadObj)
    const attachment = Object.assign({}, this.state.attachment)

    attachment.AttachmentPath = signedRateCon
    attachment.DateSigned = new Date().toISOString()
    attachment.SignedFromIp = this.state.ip
    attachment.DeviceInformation = "browserVersion1b " + navigator.userAgent

    const updatedDoc = await this.loadService.UpdateDocument(attachment)

    var signedLog = {
      ParentId: loadObj.Load.LoadNumber,
      ParentType: "Load",
      Description: "Rate confirmation has been signed.",
      Time: new Date(),
      User: this.state.name,
    }

    const emailHTML = renderEmail(
      <EmailBody title="Signed Rate Confirmation">
        <h1>
          Please see attached file for full details of load{" "}
          {this.state.loadObj.Trip.TripNumber}
        </h1>
        <div style={emailStyle.cardbody}>
          <div style={emailStyle.space}>
            <h3 style={emailStyle.label}>Rate</h3>
            <h2 style={emailStyle.value}>{this.getTotalCharges()}</h2>
          </div>
          <div style={emailStyle.space}>
            <h3 style={emailStyle.label}>Total Miles</h3>
            <h2 style={emailStyle.value}>
              {this.state.loadObj.Trip.TotalMiles} mi.
            </h2>
          </div>
          <div style={emailStyle.space}>
            <h3 style={emailStyle.label}>Total Weight</h3>
            <h2 style={emailStyle.value}>
              {this.state.loadObj.Trip.TotalWeight} lbs
            </h2>
          </div>
        </div>
        <div style={emailStyle.container}>
          {this.state.loadObj.Trip && this.state.loadObj.Trip.Stops.length > 0
            ? this.state.loadObj.Trip.Stops.map((stop, i) => (
                <div key={i} style={emailStyle.card}>
                  <h2
                    style={
                      stop.StopType === "Pickup"
                        ? emailStyle.stoptype.pickup
                        : emailStyle.stoptype.delivery
                    }
                  >
                    {stop.StopType}
                  </h2>
                  <div style={emailStyle.cardbody}>
                    <div>
                      {stop.Company ? (
                        <div>
                          <h3 style={emailStyle.label}>Shipper</h3>
                          <h2 className="text-md font-bold">
                            {stop.Company.Name}
                          </h2>
                          <h2 style={emailStyle.value}>
                            <span>{stop.Company.PhysicalAddress.Street}, </span>
                            <span>{stop.Company.PhysicalAddress.City}, </span>
                            <span>{stop.Company.PhysicalAddress.State} </span>
                            <span>{stop.Company.PhysicalAddress.Zip}</span>
                            {stop.Company.PhysicalAddress.Phone &&
                            stop.Company.PhysicalAddress.Phone.length > 0 ? (
                              <a
                                href={`tel:${stop.Company.PhysicalAddress.Phone[0]}`}
                                className="text-blue-500 hover:text-blue-400"
                              >
                                {" "}
                                {stop.Company.PhysicalAddress.Phone[0]}
                              </a>
                            ) : null}
                          </h2>
                        </div>
                      ) : null}
                      {stop.StopDate ? (
                        <div>
                          <h3 style={emailStyle.label}>Expected</h3>
                          <h2 style={emailStyle.value}>{stop.StopDate}</h2>
                        </div>
                      ) : null}
                    </div>
                    <div style={emailStyle.space}>
                      {stop.Company ? (
                        <div>
                          {stop.Company.ShippingHours ? (
                            <div>
                              <h3 style={emailStyle.label}>
                                Shipping/Receiving Hours
                              </h3>
                              <h2 style={emailStyle.value}>
                                {stop.Company.ShippingHours}
                              </h2>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                      {stop.Appointment ? (
                        <div>
                          <h3 style={emailStyle.label}>Appt. Time</h3>
                          <h2 style={emailStyle.value}>{stop.Appointment}</h2>
                        </div>
                      ) : null}
                      {stop.References && stop.References.length > 0 ? (
                        <div>
                          <h3 style={emailStyle.label}>References</h3>
                          {stop.References.map((reference, i) => (
                            <h2 key={i} style={emailStyle.value}>
                              {reference.Name} <span>{reference.Value}</span>
                            </h2>
                          ))}
                        </div>
                      ) : null}
                    </div>
                    <div style={emailStyle.space}>
                      {stop.Commodities && stop.Commodities.length > 0 ? (
                        <div>
                          {stop.Commodities.map((commodity, i) => (
                            <div key={i}>
                              <h3 style={emailStyle.label}>
                                {commodity.Description}
                              </h3>
                              {commodity.Weight > 0 ? (
                                <h2 style={emailStyle.value}>
                                  {commodity.Weight} lbs
                                </h2>
                              ) : (
                                <></>
                              )}
                              {commodity.NumUnits > 0 ? (
                                <h2 style={emailStyle.value}>
                                  {commodity.NumUnits}{" "}
                                  <span>{commodity.UnitType}</span>
                                </h2>
                              ) : (
                                <></>
                              )}
                              {commodity.NumPieces > 0 ? (
                                <h2 style={emailStyle.value}>
                                  {commodity.NumPieces}{" "}
                                  <span>{commodity.HandlingType}</span>
                                </h2>
                              ) : (
                                <></>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : null}
                      {stop.Instructions ? (
                        <div>
                          <h3 style={emailStyle.label}>Load Instructions</h3>
                          <h2 style={emailStyle.value}>{stop.Instructions}</h2>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </EmailBody>
    )
    var emailObj = {
      SendTo: attachment.EmailedTo
        ? attachment.EmailedTo.split(",")
        : ["developers@avantmark.com"],
      Subject: `Signed Rate Confirmation for load ${this.state.loadObj.Trip.TripNumber}`,
      Body: emailHTML,
      SendFromEmail: loadObj.Trip.TenderAs
        ? loadObj.Trip.TenderAs.toLowerCase().indexOf("hickory") > -1
          ? this.state.dispatcher.SecondaryEmail
          : this.state.dispatcher.Email
        : this.state.dispatcher.Email,
      UserId: loadObj.Trip.DispatchedBy,
      Attachments: [signedRateCon],
    }
    let result = await this.loadService.SendEmail(emailObj)
    var emailLog = {
      ParentId: loadObj.Load.LoadNumber,
      ParentType: "Load",
      Description: `Email sent to ${attachment.EmailedTo} with attachment ${signedRateCon}`,
      Time: new Date(),
      User: this.state.name,
    }
    if(loadObj.Trip.Status === "Covered"){
      loadObj.Trip.Status = "Dispatched";
      if(loadObj.Load.Status === "Covered"){
        loadObj.Load.Status = "Dispatched";
      }
    }

    await this.loadService.UpdateTrip({
      LoadTrip: loadObj,
      Logs: [signedLog, emailLog],
    })
    return true
  }

  render() {
    const {
      loadObj,
      dispatcher,
      success,
      error,
      agree,
      isSigned,
      working,
      name,
      driverName,
      phone,
      truckNum,
      trailerNum,
    } = this.state
    return (
      <div className="p-6 mt-16 lg:mt-32 lg:mb-12 rounded">
        {loadObj && dispatcher ? (
          <div className="max-w-full rounded overflow-hidden shadow-lg flex flex-wrap lg:flex-no-wrap">
            <div className="w-full lg:w-1/3 px-6 py-6">
              <div className="flex flex-wrap lg:flex-no-wrap items-center justify-between mb-8">
                <div className="w-full lg:w-1/3 flex lg:flex-none justify-center">
                  <img src={Logo} alt="Archerhub Logo" />
                </div>
                <div className="w-full lg:w-2/3 pl-3 text-center lg:text-left">
                  <h1 className="text-3xl font-bold">Archerhub</h1>
                  <h2 className="text-2xl font-semibold text-gray-700">
                    Carrier Rate & Load Confirmation
                  </h2>
                  <p className="text-xl font-semibold text-gray-700">
                    Load Number -{" "}
                    {loadObj.Trip.TripNumber ? (
                      <span className="text-gray-800 font-bold">
                        {loadObj.Trip.TripNumber}
                      </span>
                    ) : (
                      <span>Not Set</span>
                    )}
                  </p>
                  <p className="text-xl font-semibold text-gray-700">
                    Carrier -{" "}
                    {loadObj.TripCarrier ? (
                      <span className="text-gray-800 font-bold">
                        {loadObj.TripCarrier.Name} ({loadObj.TripCarrier.MCNum})
                      </span>
                    ) : (
                      <span>Not Set</span>
                    )}
                  </p>
                  <p className="text-xl font-semibold text-gray-700">
                    Date -{" "}
                    <span className="text-gray-800 font-bold">
                      {moment().format("MMMM Do YYYY")}
                    </span>
                  </p>
                </div>
              </div>
              <hr />
              <div className="mt-8 text-center lg:text-left">
                <details open>
                  <summary>
                    <h1 className="text-2xl font-bold">Important Advisory</h1>
                    <h2 className="text-lg font-semibold text-gray-600">
                      Please read before accepting.
                    </h2>
                  </summary>
                  <ul className="text-lg list-none lg:list-disc font-semibold text-gray-700 pt-4 px-4 lg:px-8">
                    <li className="py-2">
                      One invoice per email, Archerhub load number should be in
                      the subject line, please submit scanned, legible copies.
                    </li>
                    <li className="py-2">
                      Carrier invoice should contain: mailing address, factoring
                      companies and any accessorials.
                    </li>
                    <li className="py-2">
                      <span className="font-bold">Quick Pay:</span>
                      <br /> 3.5% fee | payment submitted via ACH in 72 business
                      hours. Email paperwork to{" "}
                      <a
                        href="mailto:qp@archerhub.com"
                        className="text-blue-500 hover:text-blue-400"
                      >
                        qp@archerhub.com
                      </a>
                    </li>
                    <li className="py-2">
                      <span className="font-bold">Regular Pay: </span>
                      <br />
                      Payment submitted via check in 30 days Email paperwork to{" "}
                      <a
                        href="mailto:accounting@archerhub.com"
                        className="text-blue-500 hover:text-blue-400"
                      >
                        accounting@archerhub.com
                      </a>{" "}
                      For originals: 3009 Douglas Blvd Suite 110 Roseville, CA
                      95661
                    </li>
                    <li className="py-2">
                      <span className="font-bold">For payment inquiry: </span>
                      <br />
                      Email:{" "}
                      <a
                        href="mailto:accounting@archerhub.com"
                        className="text-blue-500 hover:text-blue-400"
                      >
                        accounting@archerhub.com
                      </a>{" "}
                      <br />
                      Phone:{" "}
                      <a
                        href="tel:303-323-4933"
                        className="text-blue-500 hover:text-blue-400"
                      >
                        (303) 323-4933
                      </a>{" "}
                      EXT: 7304
                    </li>
                    <li className="py-2">
                      <span className="font-bold">Late Paperwork: </span>
                      <br />
                      Failure to submit documentation within a week of load
                      completion will result in $50 fee for each consecutive
                      week/month it is late.
                    </li>
                    <li className="py-2">
                      <span className="font-bold">Comchecks: </span>
                      <br />
                      Fee of $10 or 3.5% whichever is greater | $35 for each
                      $1000
                    </li>
                  </ul>
                </details>
              </div>
            </div>
            <div className="w-full lg:w-2/3 bg-gray-100 px-4 py-6 flex flex-wrap items-start">
              <div className="w-full flex flex-wrap lg:flex-no-wrap">
                <div className="w-full lg:w-1/2 lg:mr-5">
                  <div className="max-w-full rounded overflow-hidden shadow-md px-4 py-6 bg-white">
                    <h1 className="text-lg font-bold">Load Details</h1>
                    <hr className="my-3" />
                    <div className="py-2">
                      <h2 className="text-base font-bold text-gray-600">
                        Dispatcher
                      </h2>
                      {dispatcher.Name ? (
                        <p className="font-bold text-lg">{dispatcher.Name}</p>
                      ) : null}
                      {dispatcher.Email ? (
                        <a
                          href={`mailto:${dispatcher.Email}`}
                          className="text-blue-500 hover:text-blue-400 font-semibold"
                        >
                          {dispatcher.Email}
                        </a>
                      ) : null}
                    </div>
                    {loadObj.Trip.EquipmentLength ||
                    loadObj.Trip.EquipmentType ? (
                      <div className="py-2">
                        <h2 className="text-base font-bold text-gray-600">
                          Equipment
                        </h2>
                        <p className="font-bold text-lg">
                          {loadObj.Trip.EquipmentType || "Not set"}{" "}
                          <span>
                            {loadObj.Trip.EquipmentLength || "Not set"}
                          </span>
                        </p>
                      </div>
                    ) : null}
                    {loadObj.Trip.TotalWeight ? (
                      <div className="py-2">
                        <h2 className="text-base font-bold text-gray-600">
                          Weight
                        </h2>
                        <p className="font-bold text-lg">
                          {loadObj.Trip.TotalWeight.toLocaleString() ||
                            "Not set"}{" "}
                          lbs
                        </p>
                      </div>
                    ) : null}
                    {loadObj.Trip.TotalMiles ? (
                      <div className="py-2">
                        <h2 className="text-base font-bold text-gray-600">
                          Miles
                        </h2>
                        <p className="font-bold text-lg">
                          {loadObj.Trip.TotalMiles.toLocaleString() ||
                            "Not set"}
                        </p>
                      </div>
                    ) : null}
                    {loadObj.Trip.Temperature ? (
                      <div className="py-2">
                        <h2 className="text-base font-bold text-gray-600">
                          Temperature
                        </h2>
                        <p className="font-bold text-lg">
                          {loadObj.Trip.Temperature || "Not set"} &deg;
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="w-full lg:w-1/2 mt-4 lg:mt-0">
                  <div className="max-w-full rounded overflow-hidden shadow-md px-4 py-6 bg-white">
                    <h1 className="text-lg font-bold mb-3">Carrier Fees</h1>
                    <hr className="my-3" />
                    <table className="border-collapse border-none">
                      <tbody>
                        <tr className="border-b border-gray-400 table-row">
                          <td className="table-cell pl-3 font-bold text-sm lg:text-lg text-gray-600 inline-block border-none">
                            Net Line Haul
                          </td>
                          <td className="table-cell pl-0 inline-block lg:pl-2 border-none">
                            {loadObj.TripCarrier ? (
                              <div className="">
                                <h2 className="text-sm lg:text-lg font-bold">
                                  {this.getCarrierRate()}
                                </h2>
                              </div>
                            ) : null}
                          </td>
                        </tr>
                        {loadObj.Invoices && loadObj.Invoices.length > 0 ? (
                          <>
                            <tr className="table-row">
                              <td className="table-cell pl-3 inline-block border-none">
                                <h2 className="font-bold text-base lg:text-lg text-gray-600">
                                  Accessorial Charges
                                </h2>
                              </td>
                              <td className="table-cell pl-0 inline-block border-none"></td>
                            </tr>
                            {loadObj.Invoices.map((charge, i) => (
                              <tr
                                className="border-b border-gray-400 table-row"
                                key={i}
                                dangerouslySetInnerHTML={{
                                  __html: this.printCharge(charge),
                                }}
                              ></tr>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                        <tr className="table-row">
                          <td className="table-cell pl-3 inline-block border-none">
                            <h2 className="font-bold text-base lg:text-lg text-gray-8600">
                              Total Cost
                            </h2>
                          </td>
                          <td className="table-cell pl-0 inline-block lg:pl-2 border-none">
                            {loadObj.TripCarrier ? (
                              <div className="">
                                <h2 className="text-base lg:text-lg font-bold">
                                  {this.getTotalCharges()}
                                </h2>
                              </div>
                            ) : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <Stop className="w-full lg:overflow-y-auto mt-5">
                {loadObj.Trip.Stops && loadObj.Trip.Stops.length > 0
                  ? loadObj.Trip.Stops.map((stop, i) => (
                      <div
                        key={i}
                        className={`max-w-full rounded overflow-hidden shadow-md px-4 py-4 bg-white border-l-4 my-4 ${
                          stop.StopType === "Pickup"
                            ? "border-red-300"
                            : "border-blue-300"
                        }`}
                      >
                        <h1
                          className={`text-2xl font-bold ${
                            stop.StopType === "Pickup"
                              ? "text-red-400"
                              : "text-blue-400"
                          }`}
                        >
                          {stop.StopType}
                        </h1>
                        <div className="flex flex-wrap lg:flex-no-wrap justify-between">
                          <div className="w-full lg:w-1/3">
                            {stop.Company ? (
                              <div className="block pt-3">
                                <h2 className="font-bold text-lg text-gray-600">
                                  Shipper
                                </h2>
                                <h2 className="text-lg font-bold">
                                  <span>
                                    {stop.Company.PhysicalAddress.City},{" "}
                                  </span>
                                  <span>
                                    {stop.Company.PhysicalAddress.State}{" "}
                                  </span>
                                  <span>
                                    {stop.Company.PhysicalAddress.Zip}
                                  </span>
                                </h2>
                              </div>
                            ) : stop.StopAddress ? (
                              <div className="block pt-3">
                                <h2 className="text-lg font-bold">
                                  {stop.StopAddress.Street}
                                </h2>
                                <h2 className="text-lg font-bold">
                                  <span>{stop.StopAddress.City}, </span>
                                  <span>{stop.StopAddress.State} </span>
                                  <span>{stop.StopAddress.Zip}</span>
                                </h2>
                              </div>
                            ) : null}
                            {stop.StopDate ? (
                              <div className="block my-2">
                                <h2 className="font-bold text-lg text-gray-600">
                                  Expected
                                </h2>
                                <h2 className="text-lg font-bold">
                                  {stop.StopDate}
                                </h2>
                              </div>
                            ) : null}
                          </div>
                          <div className="w-full lg:w-1/3 lg:px-12">
                            {stop.Company ? (
                              <div className="block mt-2">
                                {stop.Company.ShippingHours ? (
                                  <div>
                                    <h2 className="font-bold text-lg text-gray-600">
                                      Shipping/Receiving Hours
                                    </h2>
                                    <h2 className="text-lg font-bold">
                                      {stop.Company.ShippingHours}
                                    </h2>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                            {stop.Appointment ? (
                              <div className="block my-2">
                                <h2 className="font-bold text-lg text-gray-600">
                                  Appt. Time
                                </h2>
                                <h2 className="text-lg font-bold">
                                  {stop.Appointment}
                                </h2>
                              </div>
                            ) : null}
                            {/* {stop.References && stop.References.length > 0 ? (
                              <div className="block my-2">
                                <h2 className="font-bold text-lg text-gray-600">
                                  References
                                </h2>
                                {stop.References.map((reference, i) => (
                                  <h2 key={i} className="text-lg font-bold">
                                    {reference.Name}{" "}
                                    <span>{reference.Value}</span>
                                  </h2>
                                ))}
                              </div>
                            ) : null} */}
                          </div>
                          <div className="w-full lg:w-1/3">
                            {stop.Commodities && stop.Commodities.length > 0 ? (
                              <div className="block my-2">
                                {stop.Commodities.map((commodity, i) => (
                                  <div key={i}>
                                    <h2 className="font-bold text-lg text-gray-800">
                                      {commodity.Description}
                                    </h2>
                                    {commodity.Weight > 0 ? (
                                      <h3 className="font-bold text-lg text-gray-600">
                                        {commodity.Weight} lbs
                                      </h3>
                                    ) : (
                                      <></>
                                    )}
                                    {commodity.NumUnits > 0 ? (
                                      <h3 className="font-bold text-lg text-gray-600">
                                        {commodity.NumUnits}{" "}
                                        <span>{commodity.UnitType}</span>
                                      </h3>
                                    ) : (
                                      <></>
                                    )}
                                    {commodity.NumPieces > 0 ? (
                                      <h3 className="font-bold text-lg text-gray-600 mt-2">
                                        {commodity.NumPieces}{" "}
                                        <span>{commodity.HandlingType}</span>
                                      </h3>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                ))}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {stop.Instructions ? (
                          <div className="w-full block">
                            <div className="block mt-2">
                              <h2 className="font-bold text-lg text-gray-600">
                                Load Instructions
                              </h2>
                              <h2 className="text-md font-semibold">
                                {stop.Instructions}
                              </h2>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ))
                  : null}
              </Stop>
              {loadObj && success ? (
                <div
                  className="bg-green-200 border-l-4 border-green-500 text-green-700 p-4"
                  role="alert"
                >
                  <p className="font-bold">Success!</p>
                  <p>
                    The Rate Confirmation has been successfully signed. You
                    should receive a copy in your email momentarily!.
                  </p>
                </div>
              ) : null}
              {loadObj && isSigned && !success ? (
                <div
                  className="bg-red-200 border-l-4 border-red-500 text-red-700 p-4"
                  role="alert"
                >
                  <p className="font-bold">Oops</p>
                  <p>This Rate Confirmation has already been signed!.</p>
                </div>
              ) : null}
              {loadObj && !isSigned && !success ? (
                <form className="w-full pt-2" onSubmit={this.handleSubmit}>
                  <div className="w-full">
                    <h2 className="text-lg text-gray-600 font-bold">
                      Driver's Information
                    </h2>
                    <div className="flex flex-wrap lg:flex-no-wrap">
                      <div className="w-full lg:w-1/2">
                        <div className="w-full">
                          <label
                            htmlFor="driverName"
                            className="block text-gray-700 text-md font-bold mb-1"
                          >
                            Name
                          </label>
                          <input
                            className="appearance-none bg-gray-300 border-gray-300 w-full py-2 px-3 placeholder-gray-600 focus:outline-none rounded mt-2"
                            id="driverName"
                            type="text"
                            name="driverName"
                            value={driverName}
                            onChange={this.handleInputChange}
                            title="Driver's Name"
                            placeholder="John Doe"
                            required
                          />
                        </div>
                        <div className="w-full my-4">
                          <label
                            htmlFor="driverPhone"
                            className="block text-gray-700 text-md font-bold mb-1"
                          >
                            Phone Number
                          </label>
                          <InputMask
                            mask="(999) 999-9999"
                            type="tel"
                            id="driverPhone"
                            title="Driver's Phone Number"
                            placeholder="(123) 456-7890"
                            className="appearance-none bg-gray-300 border-gray-300 w-full py-2 px-3 placeholder-gray-600 focus:outline-none rounded mt-2"
                            value={phone}
                            onChange={this.handleInputChange}
                            name="phone"
                            required
                          ></InputMask>
                        </div>
                      </div>
                      <div className="w-full lg:w-1/2 lg:pl-4">
                        <div className="w-full">
                          <label
                            htmlFor="truckNum"
                            className="block text-gray-700 text-md font-bold mb-1"
                          >
                            Truck Number
                          </label>
                          <input
                            className="appearance-none bg-gray-300 border-gray-300 w-full py-2 px-3 placeholder-gray-600 focus:outline-none rounded mt-2"
                            id="truckNum"
                            type="text"
                            name="truckNum"
                            value={truckNum}
                            onChange={this.handleInputChange}
                            title="Truck Number"
                            placeholder="123456"
                          />
                        </div>
                        <div className="w-full my-4">
                          <label
                            htmlFor="trailerNum"
                            className="block text-gray-700 text-md font-bold mb-1"
                          >
                            Trailer Number
                          </label>
                          <input
                            className="appearance-none bg-gray-300 border-gray-300 w-full py-2 px-3 placeholder-gray-600 focus:outline-none rounded mt-2"
                            id="trailerNum"
                            type="text"
                            name="trailerNum"
                            value={trailerNum}
                            onChange={this.handleInputChange}
                            title="Truck Number"
                            placeholder="123456"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="py-3">
                      <h2 className="text-lg text-gray-600 font-bold">
                        Disclaimer
                      </h2>
                      <p className="text-lg font-semibold text-gray-800">
                        By acknowledging acceptance, I confirm that I have the
                        authority to act on behalf of, and bind the undersigned
                        individual and/or entity and have agreed to the terms
                        and conditions.
                      </p>
                      <label className="text-gray-700 font-bold flex items-center pt-2">
                        {this.createCheckbox()}
                        <span className="text-sm">
                          I agree to the terms and conditions.
                        </span>
                      </label>
                    </div>
                    <div className="w-1/2 py-3">
                      <label
                        htmlFor="acceptName"
                        className="block text-gray-700 text-md font-bold mb-1"
                      >
                        Accepted By
                      </label>
                      <input
                        className="appearance-none bg-gray-300 border-gray-300 w-full py-2 px-3 placeholder-gray-600 focus:outline-none rounded mt-2"
                        id="acceptName"
                        type="text"
                        name="name"
                        value={name}
                        onChange={this.handleInputChange}
                        title="Accepter's Name"
                        required
                        placeholder="Jane Doe"
                      />
                    </div>
                    {/* <div className="hidden">
                      <p className="text-red-500 italic">Please Sign Below</p>
                      <Canvas className="border-solid border-2 border-gray-400 rounded">
                        <SignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: "500px",
                            height: "200px",
                            className: "sigCanvas",
                          }}
                          ref={ref => {
                            this.sigPad = ref
                          }}
                        />
                      </Canvas>
                    </div> */}
                    <div className="pt-3">
                      <Button
                        className={
                          working || agree
                            ? `bg-black hover:bg-gray-800 active:bg-gray-900 hover:text-gray-100 focus:outline-none text-gray-100 font-semibold lg:text-lg py-2 px-4 lg:px-6 mr-3 rounded`
                            : `bg-gray-500 text-gray-700 font-semibold lg:text-lg py-2 px-4 lg:px-6 mr-3 rounded opacity-50 cursor-not-allowed`
                        }
                        type="submit"
                      >
                        Accept
                        {this.state.working ? (
                          <span className="inline-block ml-1">
                            <Loader
                              type="Bars"
                              color="#3182ce"
                              height={20}
                              width={30}
                            />
                          </span>
                        ) : null}
                      </Button>
                      <Button
                        className="bg-gray-400 hover:bg-gray-500 active:bg-gray-500 focus:outline-none text-gray-800 font-semibold lg:text-lg py-2 px-4 lg:px-6 mt-3 lg:mt-0 rounded"
                        type="button"
                        click={this.reset}
                      >
                        Reset
                      </Button>
                    </div>
                    {this.error ? (
                      <div
                        class="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md"
                        role="alert"
                      >
                        <div class="flex">
                          <div class="py-1">
                            <svg
                              class="fill-current h-6 w-6 text-teal-500 mr-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                            </svg>
                          </div>
                          <div>
                            <p class="font-bold">Houston, we have a problem!</p>
                            <p class="text-sm">
                              Something went wrong with submitting this
                              agreement. You can try again or contact the person
                              who sent you this agreement.
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </form>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="my-5">
            <div className="flex justify-center">
              <Loader type="Puff" color="#3182ce" height={100} width={100} />
            </div>
            <h2 className="mt-4 text-center lg:text-2xl text-gray-800">
              Fetching Load...
            </h2>
          </div>
        )}
      </div>
    )
  }
}

export default RateconContent
