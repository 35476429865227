import React, { Component } from "react"
import queryString from "query-string"
import Layout from "../components/layout"
import RateconContent from "../components/review-ratecon/ratecon-content"

class ReviewRatecon extends Component {
  loadNum = ""
  state = {
    loadNum: null,
    attachmentId: null,
    userId:null
  }
  constructor(props) {
    super(props)
    let query = this.props.location.search
      ? queryString.parse(this.props.location.search)
      : {}
    if (query.lid && query.aid) {
      this.state = { loadNum: query.lid, attachmentId: query.aid, userId:query.uid }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      let query = this.props.location.search
        ? queryString.parse(this.props.location.search)
        : {}
      if (query.lid && query.aid) {
        this.setState({ loadNum: query.lid, attachmentId: query.aid, userId:query.uid})
      }
    }
  }
  render() {
    return (
      <Layout>
        <RateconContent
          tripNumber={this.state.loadNum}
          attachmentId={this.state.attachmentId}
          userId={this.state.userId}
        ></RateconContent>
      </Layout>
    )
  }
}

export default ReviewRatecon
