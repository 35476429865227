import axios from "axios"
import ls from "local-storage"
import Base64 from "Base64"

class AssetService {
    token = ""
    userid = ""
    constructor() {
        const usr = ls.get("wauid")
        try {
            this.token = JSON.parse(Base64.atob(usr)).Auth
            this.userid = JSON.parse(Base64.atob(usr)).Id
        } catch (err) {
            console.log(err);
        }
        //   console.log(this.token);
    }
    getToken() {
        return this.token;
    }
    CreateDriver = async (driver) => {
        const res = await axios.post(process.env.apiurl + "assets/CreateDriver", driver, {
            headers: { Authorization: "Basic " + this.token },
        })
        return res.data
    }
    CreateTruck = async (truck) => {
        const res = await axios.post(process.env.apiurl + "assets/CreateTruck", truck, {
            headers: { Authorization: "Basic " + this.token },
        })
        return res.data
    }
    CreateTrailer = async (trailer) => {
        const res = await axios.post(process.env.apiurl + "assets/CreateTrailer", trailer, {
            headers: { Authorization: "Basic " + this.token },
        })
        return res.data
    }
    DoesDriverExist = async (phone) => {
        const res = await axios.get(process.env.apiurl + `assets/DriverExist/${Base64.btoa(phone)}`, {
            headers: { Authorization: "Basic " + this.token },
        })
        return res.data
    }

}
export default AssetService

